import React from "react"

const CarouselItem = props => {
  const { person } = props
  return (
    <div className="w-100 bg-white">
      <div className="flex flex-column flex-row-l">
        <div className="w-100 w-50-l tc">
          <img
            src={person.imgUrl}
            alt="A bright blue sky"
            className="w-100 mw7 w5-ns"
          />
        </div>
        <div
          style={{ minHeight: "300px" }}
          className="w-100 w-50-l tc flex flex-column justify-center pa1 pa0-ns"
        >
          <h2 className="f2-ns mb4 f3 lh-title">{person.name}</h2>
          <div className="">
            <p className="f5 f3-ns brandon-light m0-ns lh-copy bg-white w-80 center">
              "{person.quote}"
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CarouselItem
