import React from 'react';
import { Link } from 'gatsby'

const GetNoteButton = () => {
  return (
    <Link
      className="
        f7
        link 
        ph3 ph5-ns pv3 
        mt5 mb5 mb6-ns 
        dib white 
        bg-black 
        brandon-bold 
        tracked"
      to="/get-love"
    >
      GET MY DAILY LOVE NOTE
    </Link>
  );
}

export default GetNoteButton;