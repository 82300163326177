import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import CarImage from "../images/602A9283.jpg"
import LoveNotesImg from "../images/lovenotes.png"
import GetNoteButton from "../components/getNotebutton"

import LoveNote01 from "../images/love_notes/note01.jpg"
import LoveNote02 from "../images/love_notes/note02.jpg"
import LoveNote03 from "../images/love_notes/note03.jpg"
import LoveNote04 from "../images/love_notes/note04.jpg"

import youAreAwesomeGif from "../images/youareawesome.gif"

import { Carousel } from "react-responsive-carousel"
import CarouselItem from "../components/carouselItem"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import "../css/carousel.css"

import anne from "../images/people/anne.png"
import kelsey from "../images/people/kelsey.png"
import rachel from "../images/people/rachel.png"
import EMLogo from "../components/EMLogo"

const carouselItems = [
  {
    name: "Kelsey Chapman",
    quote:
      "You are capable of so much more than you give yourself credit for! There is only one you on this planet. And no one else quite like you!",
    imgUrl: kelsey,
  },
  {
    name: "Anne Somoilov",
    quote:
      "It’s okay to slow down. Don’t rush the process. Your dreams need time and space to fully bloom!",
    imgUrl: anne,
  },
  {
    name: "Rachel Gilliam",
    quote:
      "Saying no isn't selfish, it's necessary. Do it without apology and without remorse because you're worth the YES to yourself.",
    imgUrl: rachel,
  },
  {
    name: "Emily Cassel",
    quote:
      "Keep going. Don't give up on yourself, or on your dream. Let the miracles unfold, and keep audaciously believing in yourself along the way. Proceed as if success were inevitable... 'cause it is!",
    imgUrl: kelsey,
  },
]

const IndexPage = () => (
  <Layout title="Empower + Rise">
    <section className="white bg-dusty-rose overflow-hidden">
      <div className="mt4 flex justify-around items-center w-100">
        <div>
          <Link to="/">
            <EMLogo style={{ fill: "#fff", width: "160px" }} />
          </Link>
        </div>
      </div>

      <div className="tc mt6-ns mt5">
        <h2 className="f-subheadline-ns f2 lh-title">
          We Rise Together <br />
          And It Starts With You
        </h2>

        <GetNoteButton />
      </div>

      <div className="bg-dusty-rose-light pv3-ns">
        <div className="w-100 flex justify-center">
          <img className="awesome-gif" src={youAreAwesomeGif} />
        </div>
      </div>
    </section>
    <section
      style={{
        background: 'url(' + CarImage + ')',
        backgroundPosition: "center",
      }}
      className="mb3 h5 h6-ns cover "
    />
    <section className="pa2 black mh3 mv5 mv6-ns bg-white ph5-ns tc">
      <h2 className="f2-ns f3 lh-title">
        Empowered women empower other <br className="dn dib-ns" />
        women, and we rise together.
      </h2>
      <h2 className="brandon-bold f5 f3-ns lh-title tracked mt4 rust">
        WE BELIEVE IT. WE KNOW IT
      </h2>
      <h2 className="brandon-bold f5 f3-ns lh-title tracked mt2 rust">
        WE SEE IT EVERYDAY.
      </h2>
    </section>
    <section className="pa5-ns pa3 black bg-gray">
      <h1 className="brandon-bold tracked mv4 mb5-ns tc f5 f3-ns">
        EMPOWER + RISE SHOWCASE
      </h1>
      <div className="ph5-l center w-80-ns w-90">
        <Carousel
          showThumbs={false}
          useKeyboardArrows={true}
          showStatus={false}
          showArrows={false}
          autoPlay
          emulateTouch
          interval={2500}
          infiniteLoop
        >
          {carouselItems.map(person => (
            <CarouselItem key={person} person={person} />
          ))}
        </Carousel>
      </div>
    </section>
    <section className="black bg-white ph5-ns tc">
      <img className="mw6-ns mt5 w-80 w-100-ns" src={LoveNotesImg} />

      <div className="mv5">
        <h2 className="f2-ns f3 lh-title mb3 mh2 m0-ns">
          It’s not easy to feel empowered and loved all the time.
        </h2>
        <p className="f3-ns f5 dib lh-copy brandon-light measure mh2 m0-ns">
          We want to help with that! Let us pop into your inbox each day with a
          daily love note and pick me up to remind you how incredible you are.
        </p>
      </div>

      <div>
        <h2 className="f3 f2-ns lh-title mb3">Our goal:</h2>
        <p className="f3-ns f5 dib lh-copy measure-narrow brandon-light">
          Do our part to empower you. Let’s rise together.
        </p>
      </div>

      <GetNoteButton />
    </section>

    <section className="cf black bg-white tc">
      <div className="fl w-100 w-50-m w-25-l tc pv2 pv4-ns pa3">
        <img src={LoveNote01} />
      </div>
      <div className="fl w-100 w-50-m w-25-l tc pv2 pv4-ns pa3">
        <img src={LoveNote02} />
      </div>
      <div className="fl w-100 w-50-m w-25-l tc pv2 pv4-ns pa3">
        <img src={LoveNote03} />
      </div>
      <div className="fl w-100 w-50-m w-25-l tc pv2 pv4-ns pa3">
        <img src={LoveNote04} />
      </div>
    </section>
  </Layout>
)

export default IndexPage
